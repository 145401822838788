@value max-small, max-medium from "@/styles/variables.module.css";

.cursorPointer {
  cursor: pointer;
}

.container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.imageContainer {
  position: relative;
  aspect-ratio: 376/474;
  width: 100%;
  overflow: hidden;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-fit: cover;

  @media max-medium {
    height: 100%;
  }
}

.shadowGradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
